import React from "react";

const Footer = (props) => {
  return (
    <footer style={{ display: "flex" }}>
      <div>
        <h5>SSID Display Sign & MFG Inc</h5>
        <h6>2020 Ellesmere Rd, Unit 10, Toronto, Ontario, M1h 2Z8</h6>
        <h6> Mon - Fri: 10am - 5pm EST</h6>
        <h6>Phone: (416)818-2544</h6>
        <h6>Email: info@ssiddisplaysign.ca</h6>
        <h6>
          Copyright © 2022 SSID Display Sign & MFG Inc., All Rights Reserved.
        </h6>
        <a
          href="https://www.linkedin.com/in/harrish-suhumar/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h6>Site By Harrish Suhumar</h6>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
