import React from "react";
import "./App.css";
import logo from "./img/logo.png";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";

import Enquire from "./components/enquire";
import Footer from "./components/footer";
import About from "./img/about.jpg";
// import Portfolio from "./components/portfolio";

import frame from "./img/frame.png";
import img from "./img/ballot.png";
import img2 from "./img/card.png";
import img3 from "./img/img.png";
import img4 from "./img/img2.png";
import img5 from "./img/sign.png";
import img6 from "./img/wall.png";
import about from "./img/intro.jpeg";
import aboutMob from "./img/aboutMobile.png";

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>SSID Display Sign & MFG Inc</title>
        <meta
          name="description"
          content="SSID Display sign & MFG Inc is a leading service provider of high-quality display manufacturing products in the GTA area. Our mission is to provide each and every individual that walks through our door with the utmost quality and service, in accordance with our stellar hospitality and attention to detail."
        />
      </Helmet>
      <header>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <nav className="navigation">
          <ul>
            <li>
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="portfolio"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
              >
                Enquiries
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="container">
        <section id="home">
          <img src={About} alt="Printing Banner" style={{ height: "450px" }} />
        </section>
        <section id="about">
          <h1>About Us</h1>
          <div>
            <img className="aboutImg" src={about} alt="stock about img" />
            <img className="aboutMob" src={aboutMob} alt="logo" />
            <p>
              SSID Display sign & MFG Inc is a leading service provider of
              high-quality display manufacturing products in the GTA area. Our
              mission is to provide each and every individual that walks through
              our door with the utmost quality and service, in accordance with
              our stellar hospitality and attention to detail. Our team of
              professionals works cohesively with our clients to create in-house
              visuals that elevate your idea into visual masterpieces. We take
              pride in serving everyone, from small businesses to large
              businesses – by conducting the right business with the right
              attitude.<br></br>
              <br></br>Our experienced team of diverse professionals has over 29
              years of experience in collective designs, manufacturing, and
              project management experience in POP display Industries. We’re
              here to help you elevate your ideas as the one-stop-shop for all
              your needs. When you choose SSID Design sign & MFG Inc, you choose
              quality products from a trusted partner that will ensure your
              success.
            </p>
          </div>
        </section>
        <section id="services">
          <h1>Services</h1>
          <div className="pics">
            <img src={frame} alt="example product" />
            <img src={img} alt="example product" />
            <img src={img2} alt="example product" />
            <img src={img3} alt="example product" />
            <img src={img4} alt="example product" />
            <img src={img5} alt="example product" />
            <img src={img6} alt="example product" />
          </div>
          <ul style={{ textAlign: "center" }}>
            <li>Display Manufacturing</li>
            <li>Design / Engineering /Prototype</li>
            <li>Product pick & pack</li>
            <li>CNC</li>
            <li>Laser</li>
            <li>Acrylic Bending / Fabrication</li>
            <li>Graphic Design & Printing and more…</li>
          </ul>
          <p style={{ textAlign: "center" }}>
            Reach out to us today to talk about your needs and we can get
            started on building a masterpiece together.
          </p>
        </section>
        {/* <Portfolio /> */}
        <section id="contact">
          <h1>Enquiries</h1>
          <Enquire />
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
